body,
html {
    height: 100vh;
}

body {
    background: #f5f5f5;
}

.main-brand {
    filter: brightness(0) saturate(100%) invert(40%) sepia(89%) saturate(1454%) hue-rotate(341deg) brightness(104%) contrast(98%);
}
